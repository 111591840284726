.btn-link-custom {
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
    margin: 5px;
    transition: all 0.3s ease;
    font-weight: 600;
}

.btn-link-custom:hover {
    opacity: 0.8;
    color: #fff;
}

.icon {
    margin-right: 10px;
}
