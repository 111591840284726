.sidebar {
    width: 60px; 
    background-color: #0a1946;
    transition: width 0.5s ease; 
}

.sidebar.open {
    width: 250px; 
}

.sidebar-toggle-btn {
    display: none; 
}

.sidebar-content {
    padding: 0px;
}

.sidebar-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 50px;
}

.sidebar-menu-item {
    display: flex;
    align-items: center;
    color: #fff;
    padding: 5px 20px;
    cursor: pointer;
    height: 40px; 
}

.sidebar-menu-item.active {
    background-color: orange; 
    border-right: 5px solid orangered;
}

.sidebar-menu-item:hover {
    background-color: #324a94; 
}

.sidebar-menu-item:hover .sidebar-menu-item-name {
    margin-left: 15px; 
}

.sidebar-menu-item-name {
    margin-left: 10px;
    transition: margin-left 0.3s ease; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: none; 
}

.sidebar.open .sidebar-menu-item-name {
    display: inline;
}

.sidebar-header {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebar-header .brand {
    display: flex;
    justify-content: center; 
    align-items: center; 
    height: 100%; 
}

.sidebar-header .brand img {
    width: 100%; 
    max-width: 160px; 
    height: auto;
    transition: width 0.5s ease; 
    margin: 0px 30px;
}

.sidebar.closed .brand img {
    width: 40px;
    margin: 0px 10px;
}

.sidebar-content ul a {
    text-decoration: none;
}

.sidebar-menu i{
    width: 10px;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-family: 500;
}
.sidebar-divider {
    color: #ccc;
    padding: 0;
    margin: 10px 0px;
  }
  
.sidebar-heading {
    font-size: 0.8rem;
    padding: 0 45px 5px;
    color: #ccc;
}
.sidebar.closed .sidebar-divider{
    margin: 0px;
}  
.sidebar.closed .sidebar-heading{
    padding: 10px 0px;
    text-align: center;
}
@media only screen and (max-width: 600px) {
    .sidebar {
        display: none; 
    }

    .sidebar.open {
        display: block;
        transition: width 0.5s ease; 
    }

    .sidebar-toggle-btn {
        display: block;
        background: none;
        border: none;
        padding: 10px;
        cursor: pointer;
    }

    .sidebar-menu-item-name {
        margin-left: 0;
    }
}