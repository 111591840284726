.message-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .message-table th, .message-table td {
    border: 1px solid #dddddd;
    padding: 8px;
  }
  
  .message-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .message-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .message-table tr:hover {
    background-color: #f2f2f2;
  }
  