.navbar {
    background-color: #fefefe;
    color: #333;
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
    height: 60px!important;
}
.navbar button{
    border: none;
    background: none; 
}
.logoutBtn{
    border: none;
    color: red!important;
}
.logoutBtn:hover{
    background: none!important ;
}