
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.grid-container{
  position: relative;
}


.main {
  display: flex;
  width: 100%;
  height: 100vh;
}

.sidebar {
  flex: 0 0 auto; 
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #f0f0f0;
  overflow-y: auto; /* Enable vertical scrolling if sidebar content overflows */
}

.content {
  flex: 1;
  margin-left: 250px;
}
.shifted .content {
  margin-left: 60px;
}

.container-fluid{
  flex: 1;
}

.container-fluid h1{
  font-size: 1.2rem;
}
.box{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  padding: 10px!important;
  border-radius: 12px;
}

.pagination {
  display: flex;
  list-style: none;
  margin-top: 20px;
}

.pagination__link {
  border: 1px solid #007bff;
  margin: 0 2px;
  cursor: pointer;
  border: none; /* Remove border */

}

.pagination li {
  background-color: #eaeaea; 
  padding: 1px 7px;
  margin: 0px 1px;
  color: #000;
  text-decoration: none;
}
.pagination .pagination__link--active{
  background: #007bff;
  color: #fff;
}

.pagination li a {
  text-decoration: none;
  color: #000;
}
.pagination__link--disabled{
  display: none;
}
.reset-filter:hover{
  background: #ccc;
  padding: 4px 2px;
}
.pagination__link--hidden {
  display: none;
}

.pagination .active {
  background-color: #007bff; 
  color: #fff; 
}

#nestedModal {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
  height: 70vh; 
}

#nestedModal .modal-content {
  background: rgb(231, 241, 191);
}

.nav-tabs li button{
  font-size: 1.1rem;
  background: #eaeaea;
  margin: 0px .5px;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: black;
  font-size: 10px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px; /* Use half of the width value to align it properly */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
.available {
  color: green;
}

.available-error {
  color: red;
}
