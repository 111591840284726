.settings-nav-item{
    border: 1px solid #ccc;
    margin: 2px 0px;
    width: 300px;
    background: #e8e8e8;
}
.nav-item button{
    font-size: 1.3rem;
    font-weight: 600;
    color: #000;
}
.toggle-button {
    width: 80px; 
  }
  